<template>
  <div class="p-grid card">
    <div class="p-col-12">
      <h5>Minhas Chaves PIX</h5>
    </div>

    <div class="p-col-12 p-md-6 p-md-offset-3 p-text-center">
      Para maior agilidade, nossos pagamentos de saque são realizados somente
      através do sistema pix.
    </div>

    <div class="p-col-12 p-md-6 p-md-offset-3">
      <div
        class="card p-p-3 p-text-center"
        style="background-color: #f9c851; font-weight: bold; color: red"
      >
        ATENÇÃO:<br /><br />
        Apenas registre chaves PIX pertencentes ao mesmo CPF/CNPJ cadastrado
        aqui em nosso sistema.
      </div>
    </div>

    <div class="p-col-12 p-md-6 p-md-offset-3">
      <div v-if="loading" class="p-text-center">
        <p-progress-spinner style="width: 50px; height: 50px" />
      </div>
      <template v-else>
        <div class="p-text-center" style="font-weight: bold">
          {{
            pixKeys.length
              ? "Suas Chaves Cadastradas"
              : "Você ainda não tem chaves cadastradas"
          }}
        </div>

        <div v-for="k in pixKeys" :key="k.chave" class="pix-key">
          <i
            class="pi pi-check p-mr-2"
            :style="`font-weight: bold; color: ${
              k.status === 'valida' ? 'darkgreen' : '#ccc'
            }`"
          />
          <span>
            <b>{{ k.descricao }}</b
            ><br />
            {{ k.chave }}
          </span>
          <div class="p-ml-1">
            <p-button
              icon="pi pi-trash"
              @click="removerPixKey(k)"
              class="p-button-danger p-button-rounded"
            />
          </div>
        </div>

        <div class="p-text-center p-mt-4">
          <form method="POST" @submit.prevent="addPixKey">
            <div class="p-fluid">
              <div class="p-field">
                <label> Identifique Sua Chave </label>
                <p-inputtext
                  v-model="novaChave.descricao"
                  required
                  maxlength="30"
                  placeholder="Exemplos:  Nr. Da Conta, Nubank, Bradesco, Poupança"
                />
              </div>
              <div class="p-field">
                <label>Sua Chave PIX</label>
                <p-inputtext
                  v-model="novaChave.chave"
                  maxlength="77"
                  required
                  placeholder="Digite ou cole a nova chave aqui"
                />
              </div>
            </div>

            <div
              class="p-d-flex p-flex-row-reverse p-col-12"
              style="justify-content: end"
            >
              <p-button label="Adicionar" :loading="adding" type="submit" />
            </div>
          </form>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { getService, PixKey } from "@/services/UserService";
import { computed, defineComponent, onMounted, ref } from "vue";
import Avatar from "@/components/Avatar.vue";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";

export default defineComponent({
  components: {
    Avatar,
  },
  setup() {
    const toast = useToast();

    const novaChave = ref<PixKey>({
      chave: null!,
      descricao: null!,
    });
    const loading = ref(true);
    const adding = ref(false);

    const pixKeys = ref<PixKey[]>([]);
    async function loadPixKeys() {
      loading.value = true;
      try {
        pixKeys.value = await getService().getPixKeys();
      } finally {
        loading.value = false;
      }
    }
    onMounted(loadPixKeys);

    async function addPixKey() {
      if (adding.value) {
        return;
      }
      adding.value = true;
      try {
        const key = await getService().addPixKey(novaChave.value);
        novaChave.value = {
          chave: null!,
          descricao: null!,
        };
        toast.add({
          severity: "success",
          detail: "Chave adicionada",
          life: 3000,
        });
        pixKeys.value.push(key);
      } finally {
        adding.value = false;
      }
    }

    async function doRemoverPixKey(key: PixKey) {
      try {
        pixKeys.value.splice(pixKeys.value.indexOf(key), 1);
        await getService().removePixKey(key.chave);
      } finally {
      }
    }

    const confirm = useConfirm();

    return {
      novaChave,
      loading,
      adding,
      pixKeys,
      addPixKey,
      removerPixKey(key: PixKey) {
        confirm.require({
          message: `Deseja realmente remover a chave PIX: ${key.chave}?`,
          header: "Confirmação",
          icon: "pi pi-exclamation-triangle",
          accept() {
            doRemoverPixKey(key);
          },
        });
      },
    };
  },
});
</script>

<style scoped>
.pix-key {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}
.pix-key span {
  flex: 1;
}
</style>