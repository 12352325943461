
import { getService, PixKey } from "@/services/UserService";
import { computed, defineComponent, onMounted, ref } from "vue";
import Avatar from "@/components/Avatar.vue";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";

export default defineComponent({
  components: {
    Avatar,
  },
  setup() {
    const toast = useToast();

    const novaChave = ref<PixKey>({
      chave: null!,
      descricao: null!,
    });
    const loading = ref(true);
    const adding = ref(false);

    const pixKeys = ref<PixKey[]>([]);
    async function loadPixKeys() {
      loading.value = true;
      try {
        pixKeys.value = await getService().getPixKeys();
      } finally {
        loading.value = false;
      }
    }
    onMounted(loadPixKeys);

    async function addPixKey() {
      if (adding.value) {
        return;
      }
      adding.value = true;
      try {
        const key = await getService().addPixKey(novaChave.value);
        novaChave.value = {
          chave: null!,
          descricao: null!,
        };
        toast.add({
          severity: "success",
          detail: "Chave adicionada",
          life: 3000,
        });
        pixKeys.value.push(key);
      } finally {
        adding.value = false;
      }
    }

    async function doRemoverPixKey(key: PixKey) {
      try {
        pixKeys.value.splice(pixKeys.value.indexOf(key), 1);
        await getService().removePixKey(key.chave);
      } finally {
      }
    }

    const confirm = useConfirm();

    return {
      novaChave,
      loading,
      adding,
      pixKeys,
      addPixKey,
      removerPixKey(key: PixKey) {
        confirm.require({
          message: `Deseja realmente remover a chave PIX: ${key.chave}?`,
          header: "Confirmação",
          icon: "pi pi-exclamation-triangle",
          accept() {
            doRemoverPixKey(key);
          },
        });
      },
    };
  },
});
